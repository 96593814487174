




































































































































































































































































































import { ElForm } from 'element-ui/types/form';
import { Component, Vue } from 'vue-property-decorator';
import {
  getDiseaseList,
  postDetailDisease,
  deleteDetail,
  getOneDetail,
} from '@/serve/evaluatingBefore/evaluatingStandard';
import { getTenantData } from '@/serve/medicalAppointment/institutionManage.ts';

interface FormData {
  diseaseName: string;
  diseaseType: string;
  diseaseStandardList: DiseaseStandardList[];
  id?: number | null;
  
}
interface DiseaseStandardList {
  comment: string;
  highestScore: number | string;
  lowestScore: number | string;
  remark: string;
  riskLevel: string;
  [index: string]: any;
}
interface StandarData {
  size?: number;
  current?: number;
  diseaseName?: string;
  [propName: string]: any;
}
@Component({
  name: 'EvaluatingStandard',
  filters: {},
})
export default class EvaluatingStandard extends Vue {
  public currentPage4 = 1;
  public dialogFormVisible = false;
  public formLabelWidth = '120px';
  public form = {
    illness: '',
    category: '',
  };
  public checkSign = '';
  public checkSignId = 0;
  public options = [
    { value: '疾病', label: '疾病' },
    { value: '生活习惯', label: '生活习惯' },
    { value: '饮食健康', label: '饮食健康' },
    { value: '心理压力', label: '心理压力' },
  ];
  public roleFormItem = {
    highestScore: [
      {
        required: true,
        message: '请填写指数最高得分',
        trigger: 'blur',
      },
      { pattern: /^\d{1,2}(\.\d{1,2})?$/, message: '只能保留两位小数点' },
    ],
    lowestScore: [
      {
        required: true,
        message: '请填写指数最低得分',
        trigger: 'blur',
      },
      { pattern: /^\d{1,2}(\.\d{1,2})?$/, message: '只能保留两位小数点' },
    ],
    comment: [
      {
        required: true,
        message: '请填写评语',
        trigger: 'blur',
      },
    ],
    remark: [{}],
  };

  public activeName = '低';
  public formData: FormData = {
    diseaseName: '',
    diseaseType: '疾病',
    diseaseStandardList: [
      {
        comment: '',
        highestScore: '',
        lowestScore: '',
        remark: '',
        riskLevel: '低',
      },
      {
        comment: '',
        highestScore: '',
        lowestScore: '',
        remark: '',
        riskLevel: '中',
      },
      {
        comment: '',
        highestScore: '',
        lowestScore: '',
        remark: '',
        riskLevel: '高',
      },
    ],
  };
  public tableData = [];
  public formDatas = {
    size: 10,
    current: 1,
    diseaseName: '',
    tenantId: '',
  };
  public tenantName = '';
  public restaurants = [];
  public isAdmin = sessionStorage.getItem('isAdmin');
  public created() {
    this.getDiseaseList();
  }
  public mounted() {
    this.getTenantData();
  }
  // 进入页面获取数据
  public async getDiseaseList() {
    this.$changNull(this.formDatas);
    const res = await getDiseaseList(this.formDatas);
    this.tableData = res.data;
  }
  //  表单提交按钮
  public async handlerSubmit() {
    const list = this.formData.diseaseStandardList;
    // 讲字符串转化为数字
    for (const key in list) {
      if (list[key].highestScore) {
        list[key].highestScore = Number(list[key].highestScore);
      }
      if (list[key].lowestScore) {
        list[key].lowestScore = Number(list[key].lowestScore);
      }
          /* 转换为数值的数据进行大小对比*/
      if(list[key].highestScore<list[key].lowestScore){
        this.$message({
            type: 'warning',
            message: '最低指数不可高于最高指数!',
          });
          return
      }

    }
    // 第一季表单验证
    (this.$refs['formData'] as ElForm).validate(async (valid: any) => {
      if (valid) {
        // // 第二级表单颜值
        (this.$refs['StandardList0'] as ElForm[])[0].validate(
          async (valid1: any) => {
            (this.$refs['StandardList1'] as ElForm[])[0].validate(
              async (valid2: any) => {
                (this.$refs['StandardList2'] as ElForm[])[0].validate(
                  async (valid3: any) => {
                    if (valid1 && valid2 && valid3) {
                      // this.checkSign === '修改'
                      //   ? (this.formData.id = this.checkSignId)
                      //   : '';
                      if (this.checkSign === '修改评分标准') {
                        this.formData.id = this.checkSignId;
                      } else {
                        this.formData.id = null;
                      }
                      const res = await postDetailDisease(this.formData);
                      if (res.code === 200) {
                        this.dialogFormVisible = false;
                        (this.$refs['formData'] as ElForm).resetFields();
                        (this.$refs[
                          'StandardList0'
                        ] as ElForm[])[0].resetFields();
                        (this.$refs[
                          'StandardList1'
                        ] as ElForm[])[0].resetFields();
                        (this.$refs[
                          'StandardList2'
                        ] as ElForm[])[0].resetFields();
                        this.getDiseaseList();
                        this.activeName = '低';
                      }
                    } else {
                      return false;
                    }
                  }
                );
              }
            );
          }
        );
      } else {
        return false;
      }
    });
  }

  /**获取全部租户 */
  public async getTenantData(): Promise<void> {
    const res = await getTenantData({ size: 100000, current: 1 });
    this.restaurants = res.data.records;
  }
  public handleSelect(e: any) {
    if (Object.keys(e).length > 0) this.formDatas.tenantId = e.tId;
    else this.formDatas.tenantId = '';
  }
  // 编辑按钮
  public async editRow(index: number, id: number): Promise<void> {
    this.checkSign = '修改评分标准';
    this.checkSignId = id;
    this.dialogFormVisible = true;
    const res = await getOneDetail(id);
    this.formData.diseaseName = res.data.diseaseName;
    this.formData.diseaseType = res.data.diseaseType;
    this.formData.diseaseStandardList = res.data.diseaseStandardList;
  }
  public async querySearch(queryString: any, cb: any) {
    const res = await getTenantData({
      size: 100,
      current: 1,
      name: queryString,
    });
    const restaurants = res.data.records;
    const newArr: any = [];
    restaurants.forEach((x: any) => {
      if (x.name.includes(queryString)) {
        newArr.push({ value: x.name, tId: x.id });
      }
    });
    // 调用 callback 返回建议列表的数据
    cb(newArr);
  }
  // 筛选按钮
  public onSubmit(): void {
    this.getDiseaseList();
  }
  // 点击新增按钮
  public addSign() {
    this.dialogFormVisible = true;
    this.checkSign = '添加评分标准';
  }
  // 删除按钮
  public deleteRow(row: any, id: any): void {
    console.log(row);

    (this as any).$messageBox
      .confirm(
        `您确定是否删除疾病系统为${row.diseaseName}的这一条数据`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }
      )
      .then(async () => {
        const res = await deleteDetail(id);
        if (res.code === 200) {
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
          this.getDiseaseList();
        }
      });
  }
  // 取消按钮
  public hanlderCancel() {
    this.dialogFormVisible = false;
    (this.$refs['formData'] as ElForm).resetFields();
    (this.$refs['StandardList0'] as ElForm[])[0].resetFields();
    (this.$refs['StandardList1'] as ElForm[])[0].resetFields();
    (this.$refs['StandardList2'] as ElForm[])[0].resetFields();
    this.activeName = '低';
  }
  // 点击左右箭头
  public handleSizeChange(val: any): void {
    this.formDatas.size = val;
    this.getDiseaseList();
  }
  // 点击页码
  public handleCurrentChange(val: any): void {
    this.formDatas.current = val;
    this.getDiseaseList();
  }
  // 重置按钮
  public resetForm(formName: string) {
    (this.$refs[formName] as ElForm).resetFields();
    this.tenantName = '';
    this.formDatas.tenantId = '';
    this.currentPage4 = 1;
    this.getDiseaseList();
  }
  public handleClick() {
    console.log(1);
  }
  /** 清空机构名称事件 */
  public clearName() {
    this.formDatas.tenantId = '';
  }
}
